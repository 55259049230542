import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared/shared.service';

@Injectable()
export class VendasService {

  

  constructor(private _http: HttpClient) { }

  private response: any;

  getInadimplentesByClienteId() {              
     this.response = this._http.post(`${environment.api_server}/api/assinantes/recorrencia/get/inadimplentes`,
      { clienteId: environment.client_id, ativo: false}, { headers: SharedService.header });
    return this.response;
  }

  buscaPedidoSigeCloud(codigoProduto, CPF_CNPJ) {
    let buscaPedido = {
      "codigo": codigoProduto,
      "origem": "",
      "status": "",
      "categoria": "",
      "cliente": "",
      "cpf_cnpj": CPF_CNPJ
    }

    this.response = this._http.post(`${environment.api_server}/api/sige-cloud/request/search/get`,
      { data: buscaPedido }, { headers: SharedService.header });
    return this.response;
  }

  buscaUsuarioByCPF_Newtecnoscopio(pessoaFisica, CPF_CNPJ) {
    if (pessoaFisica) {
      this.response = this._http.post(`${environment.api_server}/api/module/user/get/cpf`,
        { CPF: CPF_CNPJ }, { headers: SharedService.header });
      return this.response;
    }
    else {
      this.response = this._http.post(`${environment.api_server}/api/module/user/get/cnpj`,
        { CNPJ: CPF_CNPJ }, { headers: SharedService.header });
      return this.response;
    }

  }

  getClienteByCPF_CNPJ(cpf_cnpj, pessoaFisica) {
    if (pessoaFisica)
      return this._http.post(`${environment.api_server}/api/assinantes/get/cliente`,
        { cpf: cpf_cnpj }, { headers: SharedService.header });
    else
      return this._http.post(`${environment.api_server}/api/assinantes/get/cliente`,
        { cnpj: cpf_cnpj }, { headers: SharedService.header });
  }

  getBancoByNome(nomeBanco) {
    return this._http.post(`${environment.api_server}/api/assinantes/get/banco`,
      { nome: nomeBanco }, { headers: SharedService.header });
  }

  pagamentoSimplesCartaoCredito(objetoDmaCielo, cpf_cnpj, orderId, clienteId, produtoId, bancoId) {
    this.response = this._http.post(`${environment.api_server}/api/cielo/credit-card/simple-transaction/typed-sale`, // venda-digitada
      { Payment: objetoDmaCielo, 
        cpf_cnpj: cpf_cnpj, 
        orderId: orderId, 
        clienteId: clienteId, 
        produtoId: produtoId, 
        bancoId: bancoId }, { headers: SharedService.header });
        
    return this.response;

  }

  buscaUsuarioByCPF_SigeCloud(cpf) {
    //#region params 
    let params = {
      "nomefantasia": "",
      "cpfcnpj": cpf,
      "cidade": "",
      "uf": "",
      "cliente": false,
      "fornecedor": false,
      "pageSize": 1,
      "skip": 0
    }
    //#endregion

    this.response = this._http.post(`${environment.api_server}/api/sige-cloud/user/search/get`,
      { params: params }, { headers: SharedService.header });
    return this.response;
  }

  cancelaVendaCieloByPagamentoId(pagamentoId) {
    this.response = this._http.post(`${environment.api_server}/api/cielo/credit-card/cancel-sale/paymentId`,
      { pagamentoId: pagamentoId }, { headers: SharedService.header });
    return this.response;
  }

  consultaCEP(cep) {
    return this._http.get(`//viacep.com.br/ws/${cep}/json`)
  }

  getVendasByFilter() {
    this.response = this._http.post(`${environment.api_server}/api/assinantes/get`,
      { clienteId: environment.client_id }, { headers: SharedService.header });
    return this.response;
  }

  cadastrarCliente(cliente, nome) {
    this.response = this._http.post(`${environment.api_server}/api/assinantes/create/cliente`,
      { cliente: cliente, nome: nome }, { headers: SharedService.header });
    return this.response;
  }

  cadastrarBanco(banco) {
    this.response = this._http.post(`${environment.api_server}/api/assinantes/create/banco`,
      { banco: banco }, { headers: SharedService.header });
    return this.response;
  }

  getVendasByClienteId(clienteId, bancoId?) {
    this.response = this._http.post(`${environment.api_server}/api/assinantes/vendas/getByClienteId`,
      { clienteId: clienteId, bancoId: bancoId ? bancoId : null }, { headers: SharedService.header });
    return this.response;
  }
  
  consultaCieloVendaByPaymentId(paymentId){
    this.response = this._http.post(`${environment.api_server}/api/cielo/consulting/sale/paymentId`,
      { paymentId: paymentId }, { headers: SharedService.header });
    return this.response;
  }

  consultaCieloVendaByOrderId(merchantOrderId){
    this.response = this._http.post(`${environment.api_server}/api/cielo/consulting/sale/merchantOrderId`,
      { merchantOrderId: merchantOrderId }, { headers: SharedService.header });
    return this.response;
  }
}
