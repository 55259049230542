import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserService } from '../../shared/user/user.service';
import { AppService } from '../../app.service';

@Injectable()
export class RankingGuard implements Resolve<any> {

  token: any;
  user;

  constructor(private _http: HttpClient, private userService: UserService, private appService:AppService) { }
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {

    this.token = localStorage.getItem('token') ? localStorage.getItem('token') : undefined;
    try { this.user = this.appService.getUser().value; }
    catch (e) { }

    return new Promise(resolve => {
      // if (this.token) {
        // this.userService.hasLogged(this.token).subscribe(res => {
          // if (res['code'] === 200) {
            this.getPerPage().subscribe((res: any) => {
              if (res.code == 200) {
                const all = [];
                for (let index = 0; index < res.all.length; index++) {
                  let personalData = { nickname: '', photo: '' }
                  personalData.nickname = res.all[index].personalData.nickname;
                  personalData.photo = res.all[index].personalData.photo;

                  all.push({ personalData, signalAverage: res.all[index].signalAverage, createAt: res.all[index].createAt, qtdSignal: res.all[index].qtdSignal, evaluation: res.all[index].evaluation, qtdVotesSignals: res.all[index].qtdVotesSignals })
                }
                resolve(all)
              } else {
                resolve('not found')
              }
            })


          // } else {
            // console.log("token invalido")
            // this.getPerPagePublic().pipe(take(1)).subscribe((res: any) => {
            //   if (res.code == 200) {
            //     const all = [];
            //     for (let index = 0; index < res.all.length; index++) {
            //       let personalData = { nickname: '', photo: '' }
            //       personalData.nickname = res.all[index].personalData.nickname;
            //       personalData.photo = res.all[index].personalData.photo;

            //       all.push({ personalData, signalAverage: res.all[index].signalAverage, createAt: res.all[index].createAt, qtdSignal: res.all[index].qtdSignal, evaluation: res.all[index].evaluation, qtdVotesSignals: res.all[index].qtdVotesSignals })
            //     }
            //     resolve(all)
            //   } else {
            //     resolve('not found')
            //   }
            // })
          // }
        // })
      // } else {
        // this.getPerPagePublic().pipe(take(1)).subscribe((res: any) => {
        //   if (res.code == 200) {
        //     const all = [];
        //     for (let index = 0; index < res.all.length; index++) {
        //       let personalData = { nickname: '', photo: '' }
        //       personalData.nickname = res.all[index].personalData.nickname;
        //       personalData.photo = res.all[index].personalData.photo;

        //       all.push({ personalData, signalAverage: res.all[index].signalAverage, createAt: res.all[index].createAt, qtdSignal: res.all[index].qtdSignal, evaluation: res.all[index].evaluation, qtdVotesSignals: res.all[index].qtdVotesSignals })
        //     }
        //     resolve(all)
        //   } else {
        //     resolve('not found')
        //   }
        // })
      // }

      // return new Promise(resolve => {
      //   this.getPerPagePublic().pipe(take(1)).subscribe((res: any) => {
      //     if (res.code == 200) {
      //       const all = [];
      //       for (let index = 0; index < res.all.length; index++) {
      //         let personalData = { nickname: '', photo: '' }
      //         personalData.nickname = res.all[index].personalData.nickname;
      //         personalData.photo = res.all[index].personalData.photo;

      //         all.push({ personalData, signalAverage: res.all[index].signalAverage, createAt: res.all[index].createAt, qtdSignal: res.all[index].qtdSignal, evaluation: res.all[index].evaluation, qtdVotesSignals: res.all[index].qtdVotesSignals })
      //       }
      //       resolve(all)
      //     } else {
      //       resolve('not found')
      //     }
      //   })
      // });
    })
  }

  getPerPage() {
    return this._http.get(`${environment.api_server}/api/module/user/ranking`);
  }

  // getPerPagePublic() {
  //   return this._http.get(`${environment.api_server}/api/module/user/ranking/private`);
  // }
}
