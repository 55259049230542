import { SharedService } from './../../shared/shared.service';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService {

  constructor(
    private http: HttpClient
  ) { }

  createUserAccount(userFormData: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}/api/user/create`, { user: userFormData }, { headers: SharedService.header });
  }

  createPasswordToken(email: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}/api/user/new-password-token`, email, { headers: SharedService.header });
  }

  createActivationToken(email: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.api_server}/api/user/new-activation-token`, email, { headers: SharedService.header });
  }
}
