import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SignalService } from '../signal.service';
import { environment } from '../../../../environments/environment'
import { AppService } from '../../../app.service';

@Injectable()
export class ListV2Guard implements Resolve<any>, CanActivate {

  emailUser = localStorage.getItem('email') || sessionStorage.getItem('email');

  constructor(private _signalService: SignalService, private _router: Router, private _appService: AppService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    

    return new Promise((resolve) => {
    
        let url = `${environment.url_version}/sinais-filtro`;
        let obj =   { 
            searchType: '',
            oscilograma: '',
            module: '',
            validated: '',
            brand: '',
            model: '',
            year: '',
            sett: '',
            type: '',
            injSystem: '',
            search: '',
            order: '',
            recent: '',
            pag: ''
          }
          
        this._router.navigate([url, obj]);
        // console.log("list-v2.guard : canActive resolve(false)");
        resolve(false);
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return new Promise(async (resolve) => {
      // console.log('listv2 - resolve');
      if (!route.params.page) {
        // let url = this._appService.user.value.adm ? 'admin/signal' : 'user/signal';
        let url = this._appService.user.value.adm ? 'admin/signal' : `${environment.url_version}/sinais-filtro`;

        this._router.navigate([url, { searchType: '',
        oscilograma: '',
        module: '',
        validated: '',
        brand: '',
        model: '',
        year: '',
        sett: '',
        type: '',
        injSystem: '',
        search: '',
        order: '',
        recent: '',
        pag: '' }]);
        return resolve(false);
      }

      // let query_list = await this._signalService.query_list.getValue();

      // if (Object.keys(query_list).length < 1) {
      //   let query = await JSON.parse(sessionStorage.getItem('last_query_for_signal'));
      //   if (query) {
      //     await this._signalService.query_list.next(query);
      //     query_list = await query;
      //   }
      // }

      // if (!this._signalService.tag_list.getValue()[0]) {
      //   let tags = await JSON.parse(sessionStorage.getItem('tag_list_for_signal'));
      //   if (tags) {
      //     await this._signalService.tag_list.next(tags);
      //   }
      // }

      // let checkBoxPrivate = sessionStorage.getItem('checkBoxPrivate');
      // let checkBox = false;

      // if (checkBoxPrivate == 'true') checkBox = true;
      // if (checkBoxPrivate == 'false') checkBox = false;
      // if (checkBoxPrivate == null) checkBox = false;

      // let res: any = await this._signalService.filterSignals({ tags: query_list, ...route.params, email: this.emailUser }, checkBox).pipe(take(1)).toPromise();

      // let resPrivate: any = await this._signalService.filterSignalsPrivate({ tags: query_list, ...route.params, email: this.emailUser }).pipe(take(1)).toPromise();

      // if (resPrivate.signals.length > 0)
      //   sessionStorage.setItem('showCheckBoxPrivate', 'true');

      // else
      //   sessionStorage.setItem('showCheckBoxPrivate', 'false');


      resolve(true);
    });

  }

}
