import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SignalService } from '../../shared/signal/signal.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NoticeComponent } from '../alerts/notice/notice.component';

@Component({
  selector: 'app-notificar-exclusao',
  templateUrl: './notificar-exclusao.component.html',
  styleUrls: ['./notificar-exclusao.component.scss']
})

export class NotificarExclusaoComponent implements OnInit, AfterViewInit {

  // matcher = new ErrorState();
  // controls = new Controls();
  // formEmail = this.controls.emailForToken;

  excluirSinal = new FormGroup ({resposta: new FormControl('')})

  mostrarErro = false;

  constructor(
    private signalService: SignalService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
    // this.controls.setService(this._service);
  }

  ngAfterViewInit() {
    //this.formEmail.setAsyncValidators([this.validEmail.bind(this)])
  }

  cancel() {
    this.dialog.closeAll();
  }

  async confirm(signalId) {

    if(this.excluirSinal.get('resposta').value == ""){
      this.mostrarErro =  true;
      return;
    }
    else{
      this.mostrarErro = false;
    }
     const res:any = await this.signalService.notificarExclusaoSinal(signalId, this.excluirSinal.value).pipe(take(1)).toPromise();
      this.dialog.closeAll();
     
     this.dialog.open(NoticeComponent, {
      data: {
        message: res.msg,
        confirmButtonText: 'OK',
      }
     })
    }
}


