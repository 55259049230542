import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared/shared.service';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/Rx';
import { VideoListDirective } from './signal-update-v2/video-list.directive';
import { take, tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class SignalService {
  
  public query_list = new BehaviorSubject({});
  public tag_list = new BehaviorSubject([]);
  static searchSignal: EventEmitter<any> = new EventEmitter();

  LOGS = false;

  constructor(private _http: HttpClient) { }

  emitterSearchSignal() {
    SignalService.searchSignal.emit();
  }

  filterSignals(tags: any = {}, checkBoxPrivate) {

    // console.log("tags = {} ", tags);
    // busca por usuário
    let email = [];
    let sinalId = ''
    if (tags.tags.module[0] === "Busca-usuario") {
      delete tags.tags.module;
      // se está procurando 
      if (tags.tags.search) {
        // console.log('if');
        email = [tags.tags.search];
        tags.tags.search = "";
      }
      // se não busca os próprios sinais
      else {
        // console.log('else');
        email = [tags.email];
        sinalId = tags.sinalId;
      }
      Object.assign(tags.tags, { email: email });
    }
    Object.assign(tags, { limit: 5 });

    if (!checkBoxPrivate) {

      if (environment.encrypt_api) {

        let now = this.cripytoRequestNow(); if (this.LOGS) console.log('now: ', now); if (this.LOGS) console.log('tags :', tags);
        let data = this.cripytoData(tags, now); if (this.LOGS) console.log('data: '); if (this.LOGS) console.log(data);
        let api_request = `${environment.api_server}/api/module/user/signal/filter/list/${now}`; if (this.LOGS) console.log('request API: ', api_request);
        
        return this._http.post(api_request, data, { headers: SharedService.header });

      } else {

        let api_request = `${environment.api_server}/api/module/user/signal/filter/list`;
        return this._http.post(api_request, tags, { headers: SharedService.header });
      }

    }

    if (checkBoxPrivate) {
      console.log('signal component: filterSignalsPrivate');
      return this.filterSignalsPrivate(tags);
    }
  }

  filterSignalsPrivate(tags = {}) {
    return this._http.post(`${environment.api_server}/api/module/user/signal/filter/list/private`, tags, { headers: SharedService.header })
  }

  cripytoRequestNow() {
    return Date.now();
  }

  cripytoData(data, now) {
    let LOGS = false;
    now = parseInt(now);
    var hoje = new Date(now); if (LOGS) console.log('hoje: ', hoje)
    let dia = hoje.getDate();
    let mes = hoje.getMonth() + 1;
    let ano = hoje.getFullYear();
    let secret = `secret: ${now + 3 * dia + 5 * mes * 7 * ano}`; if (LOGS) console.log(secret), console.log(dia, mes, ano);
    let encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), secret.trim()).toString();
    return { encrypt: encrypt };
  }

  hasSinalsPrivate() {
    return this._http.get(`${environment.api_server}/api/module/user/signal/filter/list/private/validate`, { headers: SharedService.header })
  }

  getFilterBuffer(tags) {
    return this._http.post(`${environment.api_server}/api/module/software/buffer/filter`, tags, { headers: SharedService.header });
  }

  filtroDropbox(tags,validado) {
    tags.validado = validado;
    return this._http.post(`${environment.api_server}/api/module/user/signal/filter/dropbox`, tags, { headers: SharedService.header });
  }

  getSignalTypesInfo(params: string) {
    return this._http.get(`${environment.api_server}/api/module/vehicle/sigType/${params}`, { headers: SharedService.header });
  }

  getVehiclesInfo(mod: string = '', brand: string = '', model: string = '') {
    return this._http.post(`${environment.api_server}/api/module/vehicle/`, { mod: mod, brand: brand, model: model }, { headers: SharedService.header });
  }

  getUserInfoForSignal(filter?: string, page = 0, limit = 10) {       //`${environment.api_server}/api/module/user/search/for/signal`
    return this._http.post(`${environment.api_server}/api/module/user/search/for/signal`, { filter: filter, page: page, limit: limit }, { headers: SharedService.header });
  }

  getUserInfoForSignalSimplified(filter?: string) {       //`${environment.api_server}/api/module/user/search/for/signal`
    return this._http.post(`${environment.api_server}/api/module/user/search/for/signal/simplified`, { username: filter }, { headers: SharedService.header });
  }

  mapVehicles(filter, query = {}, call = {}, page = 0, limit = 10) {
    return this._http.post(`${environment.api_server}/api/module/vehicle/map`, { call: call, filter: filter, query: query, page: page, limit: limit }, { headers: SharedService.header });
  }

  distincValForvehicle(val) {
    return this._http.post(`${environment.api_server}/api/module/vehicle/distinct`, { call: val }, { headers: SharedService.header });
  }

  getAll() {
    // console.log("getAll");
    return this._http.get(`${environment.api_server}/api/module/admin/buffer`, { headers: SharedService.header })
  }

  update(id, buffer) {
    return this._http.put(`${environment.api_server}/api/module/admin/buffer/${id}`, buffer, { headers: SharedService.header })
  }

  delete(id) {
    return this._http.delete(`${environment.api_server}/api/module/admin/buffer/${id}`, { headers: SharedService.header })
  }

  getList(page = 1, limit = 10, search = 'NXD', by = 'NXD', order = 1) {
    return this._http.get(`${environment.api_server}/api/module/user/signal/index/${page}/size/${limit}/search/${search}/by/${by}/order/${order}`, { headers: SharedService.header })
  }
  getSignalById(id) {
    return this._http.get(`${environment.api_server}/api/module/admin/buffer/${id}`, { headers: SharedService.header });
  }

  notificarExclusaoSinal(id, resposta) {
    return this._http.post(`${environment.api_server}/api/module/user/buffer/notification/delete/`, { id, resposta }, { headers: SharedService.header });
  }

  getSignalByEmail(email, page) {
    return this._http.post(`${environment.api_server}/api/module/user/buffer/get/page`, { email, page }, { headers: SharedService.header });
  }

  evaluation(id, val) {
    // console.log('===> Service', id, val)
    return this._http.put(`${environment.api_server}/api/module/user/signal/${id}/evaluation/${val}`, {}, { headers: SharedService.header });
  }
  getResolverSignal(id) {
    return this._http.get(`${environment.api_server}/api/module/admin/signal/${id}`, { headers: SharedService.header })
  }

  getQuestionsBySignal(index, size, search: string = 'NXD', field, by: string = 'NXD', order: number = 1, signal) {
    search = search == '' ? 'NXD' : search;
    signal = signal == '' ? 'NXD' : signal;
    return this._http.get(`${environment.api_server}/api/module/user/forum/signal/${signal}/index/${index}/size/${size}/search/${search}/field/${field}/by/${by}/order/${order}`, { headers: SharedService.header });
  }

  getPreview(id) {
    return this._http.get(`${environment.api_server}/api/module/scope/signal/${id}/preview`, { headers: SharedService.header })
  }

  dowloadFile(id, form) {
    const f = {
      id: id,
      serial: form.serial,
      email: form.email
    }
    return this._http.post(`${environment.api_server}/api/module/admin/buffer/${id}/download`, f, { headers: SharedService.header });
  }

  toExcel(s) {
    let list = [];
    for (let index = 0; index < s.length; index++) {
      let sinal = {
        ID: s[index]._id || "",
        NOME: s[index].name || "",
        EMAIL: s[index].email || "",
        SERIAL: s[index].serial || "",
        DATA: s[index].date || "",
        VERSAO: s[index].vesion || "",
        MODULO: s[index].module || "",
        SET: s[index].sett || "",
        TIPO: s[index].signalType || "",
        MARCA: s[index].brand || "",
        MODELO: s[index].model || "",
        ANO: s[index].year || "",
        COMENTARIOS: s[index].comments || "",
        DESCRIÇAO: s[index].description || "",
        INJEÇAO: s[index].injSystem || "",
        VISIBILIDADE: s[index].visibility || "",
        INSERIDO_EM: s[index].createAt || ""
      }
      list[index] = sinal


    }
    return list;
  }

  getDiagramSignalForum(id) {
    return this._http.post(`${environment.api_server}/api/module/diagram/signal/forum`, {sinalId: id}, { headers: SharedService.header })
  }

}